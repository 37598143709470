import { Inject, Injectable } from '@angular/core';
import { Tenant, TenantCode } from './tenant.model';
import { API_BASE_URL, TreatmentProviderNetworkClient, TreatmentProviderNetworkRequestModel } from '@shared/http-clients/http-clients';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  currentTenant: Tenant = {};
  baseUrl: string
  constructor(
    private http: HttpClient,
    private treatmentProviderNetworkClient: TreatmentProviderNetworkClient,
    @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl
  }

  init() {
    const model = new TreatmentProviderNetworkRequestModel();
    model.urlPattern = location.hostname;
  
      return new Promise<void>((resolve) => {
        this.fetchNetwork(model).pipe(
          take(1),
        ).subscribe(network => {
          
          this.currentTenant.code = TenantCode[TenantCode[network.Code]];
          this.currentTenant.appTitle = network.AppTitle;
          this.currentTenant.preferedLanguage = network.PreferedLanguage;
          this.currentTenant.theme = network.Theme;
          this.currentTenant.urlPattern = network.UrlPattern;
          this.currentTenant.useNemId = network.UseNemId;
          this.currentTenant.useVideoFeature = network.UseVideoFeature;
          this.currentTenant.selfRegistration = network.SelfRegistration;
          this.currentTenant.defaultClinicProfile = network.DefaultClinicProfile;
          this.currentTenant.clinicAccessToAssignments = network.ClinicAccessToAssignments;
          this.currentTenant.clinicSearchAccess = network.ClinicSearchAccess;

          resolve();
        })
      });
  }

  // maybe for fetching the networks we should use the generated client from http-clients
  fetchNetwork(
    requestModel: TreatmentProviderNetworkRequestModel
  ): Observable<any> {
    let url_ = this.baseUrl + "/api/v2/TenantConfiguration/GetNetwork";
    url_ = url_.replace(/[?&]$/, "");
   
    return this.http
      .post<any>(url_, requestModel);
  }

  setDefaultClinicProfile(networkCode: string, defaultClinicProfile: boolean){
    return this.treatmentProviderNetworkClient.setDefaultClinicProfile(networkCode,defaultClinicProfile);
  }
}